var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dataMigration-wrapper" }, [
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "save" },
        [
          _c(
            "el-button",
            {
              staticClass: "cancle",
              attrs: { size: "medium" },
              on: { click: _vm.back },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "contentImtem" }, [
        _c("div", { staticClass: "conentLayout" }, [
          _c("div", { staticClass: "contentF" }, [
            _vm._v("配置名称： " + _vm._s(_vm.formInline.configName)),
          ]),
          _c("div", { staticClass: "contentF" }, [
            _vm._v("所属商户： " + _vm._s(_vm.formInline.operationName)),
          ]),
        ]),
        _vm.formInline.selfEntry == 1
          ? _c("div", { staticClass: "conentLayout enter" }, [
              _c("span", { staticClass: "tipicon" }),
              _c("b", { staticClass: "tiptitle" }, [_vm._v("自主入场模式")]),
              _c("div", [
                _vm._v(
                  " 适用于PDA泊位，开启自主入场后，车主在车场的有效范围内可以自主入场，车主可以在移动端进行自主停车，查看"
                ),
                _c(
                  "span",
                  {
                    staticClass: "blueColor",
                    on: {
                      click: function ($event) {
                        _vm.dialogVisible = true
                      },
                    },
                  },
                  [_vm._v("移动端呈现效果图")]
                ),
                _vm._v("。 "),
              ]),
              _c("div", [
                _vm._v(
                  "配置有效范围：" + _vm._s(_vm.formInline.entryDistance) + "米"
                ),
              ]),
              _c("div", [
                _vm._v(
                  "配置免费时长:" + _vm._s(_vm.formInline.freeTime) + "分钟"
                ),
              ]),
            ])
          : _vm._e(),
        _vm.formInline.selfExit == 1
          ? _c("div", { staticClass: "conentLayout exit" }, [
              _c("span", { staticClass: "tipicon" }),
              _c("b", { staticClass: "tiptitle" }, [_vm._v("自主离场模式")]),
              _c("div", [
                _vm._v(" 开启【申请驶离】车主可以在移动端自主驶离，查看"),
                _c(
                  "span",
                  {
                    staticClass: "blueColor",
                    on: {
                      click: function ($event) {
                        _vm.dialogVisible2 = true
                      },
                    },
                  },
                  [_vm._v("移动端呈现效果图")]
                ),
                _vm._v("。开启【场中支付】车主可以在移动端支付驶离,查看"),
                _c(
                  "span",
                  {
                    staticClass: "blueColor",
                    on: {
                      click: function ($event) {
                        _vm.dialogVisible1 = true
                      },
                    },
                  },
                  [_vm._v("移动端呈现效果图")]
                ),
                _vm._v("。 "),
              ]),
              _c("div", [_vm._v("配置驶离规则：")]),
              _vm.formInline.exitRule == "1"
                ? _c("div", [
                    _vm._v(" PDA全程审核： "),
                    _c("div", { staticClass: "tipdetail" }, [
                      _vm._v(
                        " 车主自主驶离，由PDA确认“是否以用户【申请/支付】驶离时间作为车辆驶离时间” "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.formInline.exitRule == "2"
                ? _c("div", [
                    _vm._v(" 仅限免费时长启动PDA审核： "),
                    _c("div", { staticClass: "tipdetail" }, [
                      _vm._v(
                        " 在免费时长内，车主自主驶离，由PDA确认“是否以用户【申请/支付】驶离时间作为车辆驶离时间” "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.formInline.exitRule == "3"
                ? _c("div", [
                    _vm._v(" 信赖车主： "),
                    _c("div", { staticClass: "tipdetail" }, [
                      _vm._v(
                        " 信赖车主，以车主自主驶离的【申请/支付】时间为停车记录的截止时间 "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.formInline.exitRule == "4"
                ? _c("div", [
                    _vm._v(" 取信地磁： "),
                    _c("div", { staticClass: "tipdetail" }, [
                      _vm._v(
                        " 取信地磁，以地磁上报驶离的时间为停车记录的截止时间，车主驶离即生成欠费订单；视频泊位不支持取信地磁离场 "
                      ),
                    ]),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
    ]),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.dialogVisible,
          expression: "dialogVisible",
        },
      ],
      staticClass: "mask",
      style: {
        "z-index": "10000",
        "background-image": "url(" + _vm.picUrl + ")",
      },
      on: {
        click: function ($event) {
          _vm.dialogVisible = false
        },
      },
    }),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.dialogVisible1,
          expression: "dialogVisible1",
        },
      ],
      staticClass: "mask",
      style: {
        "z-index": "10000",
        "background-image": "url(" + _vm.picUrl1 + ")",
      },
      on: {
        click: function ($event) {
          _vm.dialogVisible1 = false
        },
      },
    }),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.dialogVisible2,
          expression: "dialogVisible2",
        },
      ],
      staticClass: "mask",
      style: {
        "z-index": "10000",
        "background-image": "url(" + _vm.picUrl2 + ")",
      },
      on: {
        click: function ($event) {
          _vm.dialogVisible2 = false
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }